<template>
  <vx-card title="TOP PROMO">
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-button
          v-on:click="submit()"
          color="primary"
          icon-pack="feather"
          icon="icon-inbox"
          >Submit</vs-button
        >
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="5%"></vs-th>
            <vs-th>
              <vs-checkbox
                v-on:click="approveAll()"
                v-model="statusApproveAll"
                color="primary"
                >Approve All</vs-checkbox
              >
            </vs-th>
            <vs-th>
              <vs-checkbox
                v-on:click="rejectAll()"
                v-model="statusRejectAll"
                color="warning"
                >Reject All</vs-checkbox
              >
            </vs-th>
            <vs-th>
              <vs-checkbox
                v-on:click="inquiryAll()"
                v-model="statusInquiryAll"
                color="warning"
                >Inquiry All</vs-checkbox
              >
            </vs-th>
            <vs-th sort-key="code">TOP Code/Name</vs-th>
            <vs-th sort-key="type">TOP Model </vs-th>
            <vs-th sort-key="customer category">TOP Value </vs-th>
            <vs-th sort-key="supplier_id">Supplier</vs-th>
            <vs-th sort-key="valid_from">Valid From</vs-th>
            <vs-th sort-key="valid_to">Valid To</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              :class="data[indextr].class"
            >
              <vs-td class="whitespace-no-wrap">
                <template>
                  <vx-tooltip text="Show">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      @click.stop="handleShow(data[indextr].id)"
                      icon="icon-eye"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td>
                <vs-checkbox
                  v-on:click="approve(indextr, data[indextr].id)"
                  :checked="id_approve.includes(tr.id)"
                  color="primary"
                ></vs-checkbox>
              </vs-td>
              <vs-td>
                <vs-checkbox
                  v-on:click="reject(indextr, data[indextr].id)"
                  :checked="id_reject.includes(tr.id)"
                  color="warning"
                ></vs-checkbox>
              </vs-td>
              <vs-td>
                <vs-checkbox
                  v-on:click="inquiry(indextr, data[indextr].id)"
                  :checked="id_inquiry.includes(tr.id)"
                  color="warning"
                ></vs-checkbox>
              </vs-td>
              <vs-td :data="data[indextr].code">{{ data[indextr].code }}</vs-td>
              <vs-td :data="data[indextr].type">{{
                data[indextr].top_model
              }}</vs-td>
              <vs-td
                :data="
                  data[indextr].date
                    ? data[indextr].date
                    : data[indextr].payment_term_name
                "
                >{{
                  data[indextr].date
                    ? data[indextr].date
                    : data[indextr].payment_term_name
                }}</vs-td
              >
              <vs-td :data="data[indextr].supplier_name">{{
                data[indextr].supplier_name
              }}</vs-td>
              <vs-td :data="data[indextr].valid_from">{{
                data[indextr].valid_from | formatDate
              }}</vs-td>
              <vs-td :data="data[indextr].valid_to">{{
                data[indextr].valid_to | formatDate
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <!-- form component -->
            <!-- <detail @close="handleClose" :id="id_item"> </detail> -->
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
// import Detail from "./detail.vue";
import moment from "moment";

export default {
  components: {
    // Detail,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      index1: 0,
      data: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      id_item: 0,
      statusApproveAll: false,
      statusRejectAll: false,
      statusInquiryAll: false,
      id_approve: [],
      id_reject: [],
      id_inquiry: [],
      purchase_return_id: [],
      persenTolerance: 0,
    };
  },
  methods: {
    submit() {
      if (
        this.id_approve.length == 0 &&
        this.id_reject.length == 0 &&
        this.id_inquiry.length == 0
      ) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Select data first.",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.loading();
        let param = {
          crl_id_approve: this.id_approve,
          crl_id_reject: this.id_reject,
          crl_id_inquiry: this.id_inquiry,
        };
        this.$http
          .post("/api/v1/master/productFestives/approve", param)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status: 1,
            is_approval: true,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_records;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_seardh;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;

            this.setStartEnd();
            this.$vs.loading.close();
          }
        });
    },

    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    handleShow(id) {
      this.$router.push({
        name: "product-festives-show",
        params: { id: id, is_approval: true },
      });
    },
    approveAll() {
      this.statusRejectAll = false;
      let _this = this;
      this.table.data.forEach(function (element) {
        element.status_checked_reject = false;
        element.status_checked_approve = true;
        element.status_checked_inquiry = false;

        if (!_this.id_approve.includes(element.id)) {
          _this.id_approve.push(element.id);
        } else {
          var index2 = _this.id_approve.indexOf(element.id);
          if (index2 !== -1) {
            _this.id_approve.splice(index2, 1);
          }
        }
      });
      this.id_reject = [];
      this.id_inquiry = [];

      // console.log(this.id_approve);
      // console.log(this.id_reject);
    },
    rejectAll() {
      this.statusApproveAll = false;
      let _this = this;
      this.table.data.forEach(function (element) {
        element.status_checked_reject = true;
        element.status_checked_approve = false;
        element.status_checked_inquiry = false;

        if (!_this.id_reject.includes(element.id)) {
          _this.id_reject.push(element.id);
        } else {
          var index2 = _this.id_reject.indexOf(element.id);
          if (index2 !== -1) {
            _this.id_reject.splice(index2, 1);
          }
        }
      });
      this.id_approve = [];
      this.id_inquiry = [];
      console.log(this.purchase_return_id);

      // console.log(this.id_approve);
      // console.log(this.id_reject);
    },
    inquiryAll() {
      this.statusApproveAll = false;
      this.statusRejectAll = false;
      let _this = this;
      this.table.data.forEach(function (element) {
        element.status_checked_inquiry = true;
        element.status_checked_reject = false;
        element.status_checked_approve = false;

        if (!_this.id_inquiry.includes(element.id)) {
          _this.id_inquiry.push(element.id);
        } else {
          var index2 = _this.id_inquiry.indexOf(element.id);
          if (index2 !== -1) {
            _this.id_inquiry.splice(index2, 1);
          }
        }
      });
      this.id_approve = [];
      this.id_reject = [];
      console.log(this.purchase_return_id);

      // console.log(this.id_approve);
      // console.log(this.id_reject);
    },
    approve(index, id_item) {
      console.log("approve");
      if (!this.id_approve.includes(id_item)) {
        this.id_approve.push(id_item);
      } else {
        var index2 = this.id_approve.indexOf(id_item);
        if (index2 !== -1) {
          this.id_approve.splice(index2, 1);
        }
      }
      var index1 = this.id_reject.indexOf(id_item);
      if (index1 !== -1) {
        this.id_reject.splice(index1, 1);
      }

      var index3 = this.id_inquiry.indexOf(id_item);
      if (index3 !== -1) {
        this.id_inquiry.splice(index3, 1);
      }

      // console.log(this.id_approve);
      // console.log(this.id_reject);
    },
    reject(index, id_item) {
      console.log("reject");
      if (!this.id_reject.includes(id_item)) {
        this.id_reject.push(id_item);
      } else {
        var index2 = this.id_reject.indexOf(id_item);
        if (index2 !== -1) {
          this.id_reject.splice(index2, 1);
        }
      }

      var index1 = this.id_approve.indexOf(id_item);
      if (index1 !== -1) {
        this.id_approve.splice(index1, 1);
      }

      var index3 = this.id_inquiry.indexOf(id_item);
      if (index3 !== -1) {
        this.id_inquiry.splice(index3, 1);
      }

      // console.log(this.id_approve);
      // console.log(this.id_reject);
    },
    inquiry(index, id_item) {
      console.log("inquiry");
      if (!this.id_inquiry.includes(id_item)) {
        this.id_inquiry.push(id_item);
      } else {
        var index2 = this.id_inquiry.indexOf(id_item);
        if (index2 !== -1) {
          this.id_inquiry.splice(index2, 1);
        }
      }

      var index1 = this.id_approve.indexOf(id_item);
      if (index1 !== -1) {
        this.id_approve.splice(index1, 1);
      }

      var index3 = this.id_reject.indexOf(id_item);
      if (index3 !== -1) {
        this.id_reject.splice(index3, 1);
      }

      // console.log(this.id_approve);
      // console.log(this.id_reject);
    },
  },
  mounted() {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(value).add(-7, "h").utc().local().format("DD MMM YYYY");
      }
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
          .core-leave-to
      
          /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.danger > td {
  background-color: #f77f7f;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
