var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"TOP PROMO"}},[_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + 'md:w-1/3 w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-table',{attrs:{"search":"","stripe":"","border":"","description":"","sst":true,"data":_vm.table.data,"max-items":_vm.table.length,"total":_vm.table.total},on:{"search":_vm.handleSearch,"change-page":_vm.handleChangePage},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,class:data[indextr].class,attrs:{"data":tr}},[_c('vs-td',{staticClass:"whitespace-no-wrap"},[[_c('vx-tooltip',{attrs:{"text":"Show"}},[_c('vs-button',{attrs:{"type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){$event.stopPropagation();return _vm.handleShow(data[indextr].id)}}})],1)]],2),_c('vs-td',{attrs:{"data":data[indextr].code}},[_vm._v(_vm._s(data[indextr].code))]),_c('vs-td',{attrs:{"data":data[indextr].type}},[_vm._v(_vm._s(data[indextr].top_model))]),_c('vs-td',{attrs:{"data":data[indextr].date
                  ? data[indextr].date
                  : data[indextr].payment_term_name}},[_vm._v(_vm._s(data[indextr].date ? data[indextr].date : data[indextr].payment_term_name))]),_c('vs-td',{attrs:{"data":data[indextr].supplier_name}},[_vm._v(_vm._s(data[indextr].supplier_name))]),_c('vs-td',{attrs:{"data":data[indextr].valid_from}},[_vm._v(_vm._s(_vm._f("formatDate")(data[indextr].valid_from)))]),_c('vs-td',{attrs:{"data":data[indextr].valid_to}},[_vm._v(_vm._s(_vm._f("formatDate")(data[indextr].valid_to)))])],1)})}}])},[_c('template',{slot:"header"},[_c('vs-dropdown',{staticClass:"cursor-pointer",attrs:{"vs-trigger-click":""}},[_c('div',{staticClass:"p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(this.table.start)+" - "+_vm._s(this.table.end)+" of "+_vm._s(this.table.total))]),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"}})],1),_c('vs-dropdown-menu',_vm._l((_vm.table.limits),function(item){return _c('vs-dropdown-item',{key:item,on:{"click":function($event){return _vm.handleChangelength(item)}}},[_c('span',[_vm._v(_vm._s(item))])])}),1)],1)],1),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"width":"5%"}}),_c('vs-th',{attrs:{"sort-key":"code"}},[_vm._v("TOP Code/Name")]),_c('vs-th',{attrs:{"sort-key":"type"}},[_vm._v("TOP Model ")]),_c('vs-th',{attrs:{"sort-key":"customer category"}},[_vm._v("TOP Value ")]),_c('vs-th',{attrs:{"sort-key":"supplier_id"}},[_vm._v("Supplier")]),_c('vs-th',{attrs:{"sort-key":"valid_from"}},[_vm._v("Valid From")]),_c('vs-th',{attrs:{"sort-key":"valid_to"}},[_vm._v("Valid To")])],1)],2),_c('vs-pagination',{staticStyle:{"padding-top":"5px"},attrs:{"total":_vm.table.totalPage},model:{value:(_vm.setPage),callback:function ($$v) {_vm.setPage=$$v},expression:"setPage"}})],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? _vm.detailShow + 'md:w-2/3 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1)],1)],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }